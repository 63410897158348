<template>
  <section class="dtc-main-section mt-2">
    <div class="dtc-search">
      <h4>下載專區:</h4>
      <b-input-group prepend="檔案名稱">
        <b-input
          v-model.trim="filename"
          placeholder="請輸入檔案名稱"
          @keydown.enter="searchDb"
        ></b-input>
      </b-input-group>
      <b-button variant="primary" @click="searchDb">進行查詢</b-button>
      <b-button variant="danger" @click="clearSearch">清除條件</b-button>
    </div>

    <header class="dtc-grid-header my-dark">
      <div v-for="(item, i) in headers" :key="i">{{ item }}</div>
    </header>
    <main class="dtc-grid-header2" v-for="(item, i) in items" :key="i">
      <div style="width:100px;cursor: pointer;" @click="openfile(item)">
        <img src="@/pictures/download.svg" style="width:100%;height:40px;" />
      </div>
      <div class="file-content">
        <div class="subject" @click="openfile(item)">
          {{ item.Subject ? item.Subject : "暫無資料" }}
        </div>
        <div class="content" :title="item.Content">
          {{ item.Content ? item.Content : "暫無資料" }}
        </div>
      </div>
      <div>
        <div>開始: {{ item.startTime ? item.startTime : "暫無資料" }}</div>
        <div>截止: {{ item.endTime ? item.endTime : "暫無資料" }}</div>
      </div>
      <div>
        {{ item.Creater ? item.Creater : "暫無資料" }}
      </div>
      <div :title="item.Remark">
        {{ item.Remark ? item.Remark : "暫無資料" }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";
// import downloadPictiue from "@/pictures/download.svg";

const headers = ["下載", "檔案內容", "下載期限", "建立人員", "備註"];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });

export default {
  name: "downarea",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      filename: "",
    };
  },
  components: {},
  computed: {},

  methods: {
    openfile(item) {
      if (!Boolean(item.FileLocation)) {
        this.$bvToast.toast(`下載專區`, {
          title: "暫無檔案!",
          autoHideDelay: 5000,
          variant: "danger",
        });
      } else {
        let domainName = document.domain;
        let fullPath = `api${item.FileLocation}`;
        window.open(fullPath);
      }
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.filename = "";
      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = [""];

      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj); // + no + filter + filter2;

      let Items = "";
      let Count = "";
      let result = {};
      if (Boolean(this.filename)) {
        qs = `${qs}&subject=${this.filename}`;
        result = await window.axios.get("FileManagment/GetByNowSearch" + qs);
      } else {
        result = await window.axios.get("FileManagment/GetNowAll" + qs);
      }
      Items = result.Items;
      Count = result.Count;

      Items.length ? (this.keys = Object.keys(Items[0])) : "";
      if (Items.length) {
        Items = Items.map((s) => {
          s.startTime = s.StartDate
            ? moment(s.StartDate).format("YYYY-MM-DD HH:mm")
            : "";
          s.endTime = s.EndDate
            ? moment(s.EndDate).format("YYYY-MM-DD HH:mm")
            : "";
          return s;
        });
      }
      this.items = Items;
      this.rowCount = Count;
      this.totalCountStr = `共${Count} 筆`;
    },
  },
  async mounted() {
    this.getData();
    this.$root.$on("get-Newest-DownloadAreaData", () => this.getData());
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  overflow-x: auto;
  padding: 12px;
  position: relative;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 110px 500px 310px 180px 400px;
  grid-auto-flow: column;
  text-align: center;
  border: 1px solid black;
  > div {
    border-right: 1px solid #e4e4ec;
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    //max-height: 20px;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-header2 {
  border: 1px solid #bfbfbf;
  border-top: 0px solid #bfbfbf;
  > div {
    border-right: 0px solid black;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #125fa3;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: repeat(34, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 120px;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
</style>
